<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">打印机设置</div>
		
		<div class="mt20">
			<a-spin :spinning="loading">
				<div class="bg-w pd30" style="width: 100%;">
					 <a-alert message="暂时只支持易联云打印机,后续会继续添加" banner />
					
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" class="mt20">
						
						<a-form-model-item required label="应用类型">
							<a-radio-group v-model="form.app_type" button-style="solid">
							        <a-radio-button :value="'yly'">
							          易联云
							        </a-radio-button>
							        <a-radio-button :value="'feie'">
							          飞鹅
							        </a-radio-button>
							 </a-radio-group>
						</a-form-model-item>
						
						<div v-if="form.app_type=='yly'">
							<a-form-model-item required label="应用id">
								<a-input v-model="form.client_id" placeholder="输入应用id" style="width: 400px;"></a-input>
							</a-form-model-item>
							<a-form-model-item required label="应用私钥">
								<a-input v-model="form.client_secret" placeholder="输入应用私钥" style="width: 400px;"></a-input>
							</a-form-model-item>
						</div>
						
						<div v-if="form.app_type=='feie'">
							<a-form-model-item required label="USER">
								<a-input v-model="form.user" placeholder="输入USER" style="width: 400px;"></a-input>
							</a-form-model-item>
							<a-form-model-item required label="UKEY">
								<a-input v-model="form.ukey" placeholder="输入UKEY" style="width: 400px;"></a-input>
							</a-form-model-item>
						</div>
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 2}">
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确定保存
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
				</div>
			</a-spin>
		</div>
		
		<div class="mt20">
			<div class="bg-w pd30" style="width: 100%;">
				<div class="ft16 ftw500 cl-black">打印机列表</div>
				<div class="mt20">
					<a-button type="primary" icon="plus" @click="addPrinterAct()">添加打印机</a-button>
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="printer_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="showPrinterListLoading">
							<div class="flex alcenter center" slot="info" slot-scope="record">
								<div v-if="record.app_type=='yly'">
									<div>终端号：{{record.machine_code}}</div>
									<div class="mt3">终端密钥：{{record.msign}}</div>
								</div>
								<td v-if="record.app_type=='feie'">
									<div>打印机编号：{{record.print_sn}}</div>
									<div class="mt3">识别码：{{record.print_key}}</div>
								</td>
							</div>
						 
							<template slot="action" slot-scope="record">
								<div class="flex center">
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											  <a-menu-item>
												<a class="menu-act" href="javascript:;" @click="editPrinterAct(record)" >
													<i class="iconfont ft14 iconedit"></i>
													<span class="ml10">编辑</span>
												</a>
											  </a-menu-item>
										</a-menu>
									  </a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
			
			<div v-if="editPrinterVisible">
				<edit-printer :visible="editPrinterVisible" :printer_id="printer_id" @cancel="cancelEditPrinter" @ok="okEditPrinter"></edit-printer>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	import editPrinter from './components/printer/modal/editPrinter.vue';
	export default{
		mixins:[listMixin],
		components:{
			editPrinter,
		},
		data(){
			return{
				loading:false,
				showPrinterListLoading:false,
				editPrinterVisible:false,
				confirmLoading:false,
				labelCol: { span: 2 },
				wrapperCol: { span: 22 },
				printer_id:0,
				form:{
					app_type:'yly',
					client_id:'',
					client_secret:'',
					user:'',
					ukey:'',
				},
				columns: [
					{title: '设备ID',dataIndex: 'printer_id',align: 'center',ellipsis: true},
					{title: '设备分类',dataIndex: 'app_type_mean',align: 'center',ellipsis: true},
					{title: '设备名',dataIndex: 'printer_name',align: 'center',ellipsis: true},
					{title: '设备信息',key: 'info',align: 'center',scopedSlots: {customRender: 'info'}},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas:[],
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/showPrinterSetting').then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			getLists(){
				if(this.showPrinterListLoading==true) return;
				this.showPrinterListLoading=true;
				this.$http.api('admin/getPrinterList',{
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res=>{
					this.pagination.total = res.total;
					this.datas = res.list;
					this.showPrinterListLoading=false;
				}).catch(res=>{
					console.log(res);
					this.showPrinterListLoading=false;
				})
			},
			
			addPrinterAct(){
				this.printer_id=0;
				this.editPrinterVisible=true;
			},
			editPrinterAct(record){
				this.printer_id=record.printer_id;
				this.editPrinterVisible=true;
			},
			cancelEditPrinter(){
				this.editPrinterVisible=false;
			},
			okEditPrinter(){
				this.editPrinterVisible=false;
				this.getLists();
			},
			
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/savePrinterSetting',{
					app_type:this.form.app_type,
					client_id:this.form.client_id,
					client_secret:this.form.client_secret,
					user:this.form.user,
					ukey:this.form.ukey,
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
		}
	}
</script>

<style>
	.select-check-box{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 2px solid #A6AEC2;
		cursor: pointer;
	}
	
	.app-sms-alert{
		height: 40px;
		background: #FEF3D4;
		border-radius: 4px;
		line-height: 40px;
		padding: 0px 16px 0px 16px;
	}
	
	.app-sms-alert-action{
		width: 72px;
		height: 28px;
		background: #FFFFFF;
		border-radius: 4px;
		line-height: 28px;
		text-align: center;
		cursor: pointer;
		
		font-size: 12px;
		font-weight: 800;
		color: #4772FF;
	}
	
	.select-check-box.active{
		background: #4772FF;
		cursor: pointer;
		border: none;
		line-height: 20px;
		text-align: center;
	}
	
	.app-sms-mobile-item .ant-input {
	    width: 160px;
	    height: 40px;
	}
	
	.app-sms-mobile-item{
		position: relative;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	
	.app-sms-mobile-item-action{
		height: 40px;
		line-height: 40px;
		position: absolute;
		top:0px;
		right: 16px;
		cursor: pointer;
	}
	
</style>
